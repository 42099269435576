<template>
  <div v-if="USER_INFO.status === 'admin'">
    <button
      class="btn btn-sm btn-outline-success shadow-none add"
      @click="addOpen"
    >
      добавить запчасти для не гарантийных ремонтов
    </button>
    <div v-if="addVisible">
      <div>
        <input
          v-model.trim="cod"
          type="text"
          placeholder="код артикул или наименование"
          class="form-control form-control-sm input-form shadow-none"
          @input="getZap"
        />
      </div>
      <div class="w">
        <input
          v-model.trim="pcs"
          type="number"
          placeholder="шт"
          class="form-control form-control-sm input-form shadow-none pcs"
        />
        <span class="p">шт.</span>
      </div>
      <div v-if="zapTitle" class="result">
        <table class="table table-sm">
          <tr>
            <th>наименование</th>
            <th>артикул</th>
            <th>код</th>
            <th>шт</th>
            <th>цена</th>
          </tr>
          <tr
            v-for="(item, idx) in zapTitle"
            :key="idx"
            @click="addZap(item.cod, item.cena)"
            class="add_tr"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.art }}</td>
            <td>{{ item.cod }}</td>
            <td>{{ item.ostatok }}</td>
            <td>{{ item.cena }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div v-else />
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddZapNotForRepairs",
  computed: {
    ...mapGetters(["LIST_ZAP", "USER_INFO"])
  },
  watch: {
    addVisible: function(newVal) {
      if (newVal) {
        this.GET_ZAP();
      }
    }
  },
  data: () => ({
    addVisible: false,
    cod: "",
    pcs: 1,
    zapTitle: null
  }),
  methods: {
    ...mapActions(["GET_ZAP"]),
    addOpen() {
      this.addVisible = !this.addVisible;
      if (!this.addVisible) {
        this.cod = "";
        this.pcs = 1;
      }
    },
    getZap() {
      if (this.cod.length > 2) {
        this.zapTitle = this.LIST_ZAP.filter(
          item =>
            item.cod.includes(this.cod) ||
            item.name.includes(this.cod) ||
            item.art.includes(this.cod)
        );
      }
    },
    addZap(cod, cena) {
      HTTPGET({
        url: "repairs/repairs_orders/add_zap_not_for_repairs.php",
        params: {
          cod,
          id: this.$route.params.id,
          pcs: this.pcs,
          cena
        }
      }).then(() => this.$emit("update"));
    }
  }
};
</script>
<style lang="scss" scoped>
.add {
  font-size: 0.7em;
}
input {
  margin-top: 5px;
}
.result {
  max-height: 200px;
  overflow: auto;
}
table {
  font-size: 0.8em;
}
.w {
  position: relative;
}
.pcs {
  width: 5em;
  display: inline;
  margin-right: 3px;
}
.p {
  position: absolute;
  bottom: 0;
}
.add_tr {
  cursor: pointer;
}
</style>
