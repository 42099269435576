<template>
  <div>
    <div v-if="order_info.cdek_id" class="alert alert-primary" role="alert">
      номер отслеживания СДЭК -
      <a
        :href="`https://www.cdek.ru/ru/tracking?order_id=${order_info.cdek_id}`"
        target="_blanc"
        >{{ order_info.cdek_id }}</a
      >
    </div>
    <div v-if="USER_INFO.status === 'admin'">
      <div
        class="input-group input-group-sm col-md-6 p-0 mb-2"
        :class="width && `col-md-${width}`"
      >
        <input
          type="text"
          class="form-control shadow-none"
          placeholder="номер отслеживания сдэк"
          aria-label="номер отслеживания сдэк"
          aria-describedby="button-addon2"
          v-model.trim="input"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-success shadow-none"
            type="button"
            id="button-addon2"
            @click="save"
            :disabled="disabled"
          >
            сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { HTTPGET } from "@/api";
export default {
  name: "CdekInfo",
  props: ["order_info", "type", "width"],
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    input: "",
    disabled: false
  }),
  methods: {
    save() {
      let url;
      if (this.type == "repairs_order") {
        url = "repairs/repairs_orders/cdek_number_edit.php";
      }
      if (this.type == "zap_order") {
        url = "order_zap/cdek_number_edit.php";
      }
      this.disabled = true;
      HTTPGET({
        url: url,
        params: {
          id: this.order_info.id,
          cdek_id: this.input
        }
      })
        .then(() => this.$emit("update"))
        .then(() => (this.input = ""))
        .then(() => {
          setTimeout(() => {
            this.disabled = false;
          }, 2000);
        });
    }
  }
};
</script>
