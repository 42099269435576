<template>
  <div id="w" class="wrapper">
    <div class="content">
      <div class="text-center item">{{ data.repairData.user }}</div>
      <div class="text-center item">{{ data.repairData.repair_number }}</div>
      <div class="text-center item">
        <button
          class="btn btn-sm btn-outline-secondary shadow-none"
          @click="confirmAct"
        >
          подтвердить акт
        </button>
      </div>
      <div class="text-center item">
        <button
          class="btn btn-sm btn-outline-danger shadow-none"
          @click="confirmActZapNoDelete"
        >
          подтвердить акт запчасти оставить
        </button>
      </div>
      <div class="text-center item">
        <button
          class="btn btn-sm btn-outline-warning shadow-none"
          @click="confirmRepair"
        >
          подтвердить ремонт
        </button>
      </div>
      <div class="text-center item" v-if="!order_info.order_finished">
        <div>
          <button
            class="btn btn-sm btn-outline-danger shadow-none"
            @click="del"
          >
            удалить ремонт
          </button>
        </div>
        <div class="custom-control custom-radio text-left">
          <input
            type="radio"
            id="customRadio1"
            name="customRadio"
            class="custom-control-input"
            checked
            value="1"
            v-model="zap"
          />
          <label class="custom-control-label" for="customRadio1"
            >запчасти перенести в заказ по не гарантии</label
          >
        </div>
        <div class="custom-control custom-radio text-left">
          <input
            type="radio"
            id="customRadio2"
            name="customRadio"
            class="custom-control-input"
            value="0"
            v-model="zap"
          />
          <label class="custom-control-label" for="customRadio2"
            >запчасти удалить</label
          >
        </div>
      </div>
      <div class="text-center item" v-if="!order_info.order_finished">
        <button
          class="btn btn-sm btn-outline-primary"
          @click="remove_in_repairs"
        >
          убрать из заявки
        </button>
      </div>
      <div class="text-center item">
        <button class="btn btn-sm btn-outline-warning" @click="close">
          отмена
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "RemoveRepair",
  props: ["data", "zap_not_for_repairs_length", "order_info"],
  data: () => ({
    zap: "1"
  }),
  mounted() {
    const elem = document.getElementById("w");
    elem.style.left = this.data.left + 30 + "px";
    elem.style.top = this.data.top + window.pageYOffset + 10 + "px";
  },
  methods: {
    close() {
      this.$emit("closeRemoveRepair");
    },
    confirmActZapNoDelete() {
      if (
        confirm(
          `по ремонту ${this.data.repairData.repair_number} будет подтвержден акт неремонтопригодности запчасти останутся в ремонте`
        )
      ) {
        HTTPGET({
          url: "acts/confirm_act.php",
          params: {
            id: this.data.repairData.remont_id,
            zap_no_delete: true
          }
        })
          .then(() => this.$emit("update"))
          .then(() => this.$emit("closeRemoveRepair"));
      }
    },
    confirmAct() {
      if (
        confirm(
          `по ремонту ${this.data.repairData.repair_number} будет подтвержден акт неремонтопригодности`
        )
      ) {
        HTTPGET({
          url: "repairs/repairs_orders/confirm_act_repair_from_order.php",
          params: {
            repair_number: this.data.repairData.repair_number,
            id: this.data.repairData.id,
            remont_id: this.data.repairData.remont_id,
            order_id: this.$route.params.id,
            zap_not_for_repairs_length: this.zap_not_for_repairs_length
          }
        })
          .then(() => {
            HTTPGET({
              url: "acts/confirm_act.php",
              params: {
                id: this.data.repairData.remont_id
              }
            });
          })
          .then(() => this.$emit("update"))
          .then(() => this.$emit("closeRemoveRepair"));
      }
    },
    del() {
      if (confirm("все данные ремонта вместе с файлами будут удалены")) {
        HTTPGET({
          url: "repairs/repair_edit/delete_repair.php",
          params: {
            id: this.data.repairData.remont_id,
            delete_zap_not_for_repair: this.zap
          }
        })
          .then(() => this.$emit("update"))
          .then(() => this.$emit("closeRemoveRepair"));
      }
    },
    confirmRepair() {
      if (confirm("ремонт будет подтвержден и удален из завки")) {
        HTTPGET({
          url: "repairs/confirm_repair.php",
          params: {
            id: this.data.repairData.remont_id
          }
        })
          .then(() => this.$emit("update"))
          .then(() => this.$emit("closeRemoveRepair"));
      }
    },
    remove_in_repairs() {
      if (confirm("ремонт будет перенесен в созданные ремонты")) {
        HTTPGET({
          url: "repairs/repair_edit/remove_in_repairs.php",
          params: {
            id: this.data.repairData.remont_id
          }
        })
          .then(() => this.$emit("update"))
          .then(() => this.$emit("closeRemoveRepair"));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: absolute;
  background: #ffffff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 300px;
}
.content {
  position: relative;
}
button {
  margin-bottom: 5px;
  width: 100%;
}
.item {
  border: 1px solid #b3b3b3;
  padding: 5px;
  margin: 5px;
}
.custom-control-label {
  cursor: pointer;
}
label {
  font-size: 0.8em;
}
</style>
