<template>
  <div>
    <div v-if="messages.length" class="wrapper">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th class="text-center" colspan="4">все сообщения по ремонтам</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, idx) in messages"
            :key="idx"
            @click="go_repair(item.remont_id)"
            class="cursor"
          >
            <td>
              <div>{{ item.user }}</div>
              <div v-if="item.read_message" class="read font-weight-light">
                <div>прочитано</div>
                <div>{{ item.read_message_format }}</div>
              </div>
            </td>
            <td>{{ item.data_format }}</td>
            <td>{{ item.message }}</td>
            <td class="text-nowrap">{{ item.repair_number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "../../../api";
export default {
  name: "MessagesFofAllRepairsInOrder",
  data: () => ({
    messages: []
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "repairs/repairs_orders/get_messages_for_all_repairs_in_order.php",
        params: {
          id: this.$route.params.id
        }
      }).then(response => (this.messages = response.data.messages));
    },
    go_repair(id) {
      this.$router.push(
        `/repairs/repair/${id}?from=repairs_order&order_id=${this.$route.params.id}`
      );
      window.localStorage.setItem("scrollMFRO", window.pageYOffset);
    }
  }
};
</script>
<style scoped>
table {
  font-size: 0.7em;
  margin: 0;
}
td {
  vertical-align: middle;
}
.wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-top: 5px;
}
.cursor {
  cursor: pointer;
}
.read {
  font-size: 0.8em;
}
</style>
