<template>
  <div class="nowrap">
    <i class="bi bi-dash-square" @click="edit('minus')"></i>
    <i class="bi bi-plus-square" @click="edit('plus')"></i>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "EditPcsZapNotForRepairs",
  props: ["pcs", "cod", "url", "id"],
  methods: {
    edit(e) {
      if (this.pcs === "1" && e === "minus") {
        return;
      }
      HTTPGET({
        url: this.url,
        params: {
          cod: this.cod,
          order_id: this.$route.params.id,
          plus_or_minus: e,
          id: this.id
        }
      }).then(() => this.$emit("update"));
    }
  }
};
</script>
<style scoped>
i {
  margin: 1px;
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
</style>
