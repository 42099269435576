<template>
  <div class="wrapper">
    <div>
      <table class="table table-sm text-center">
        <tr>
          <th colspan="2">
            история завки
          </th>
        </tr>
        <tr v-for="(item, idx) in history" :key="idx">
          <td>{{ item.status }}</td>
          <td>{{ item.data }}</td>
        </tr>
      </table>
    </div>
    <div />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "OrderHystory",
  data: () => ({
    history: []
  }),
  methods: {
    getHistory() {
      HTTPGET({
        url: "repairs/repairs_orders/order_history.php",
        params: { id: this.$route.params.id }
      }).then(response => (this.history = response.data));
    }
  },
  mounted() {
    this.getHistory();
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.8em;
}
.wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-top: 5px;
}
a {
  font-size: 0.8em;
  font-weight: bold;
}
td {
  width: 50%;
  text-align: center;
}
</style>
