<template>
  <div class="wrapper">
    <div>
      <table class="table table-sm text-center table-bordered">
        <tr>
          <th colspan="3">
            документы по заявке
            <button
              class="btn btn-sm btn-outline-primary shadow-none"
              v-if="
                USER_INFO.status === 'admin' &&
                  !order_info.order_finished &&
                  order_info.percent &&
                  order_info.order_confirm_user
              "
              @click="orderFinishedPercent"
            >
              1.5% отправлена
            </button>
          </th>
        </tr>
        <tr>
          <th>
            <a
              href="#"
              @click.prevent="upload('preOrder')"
              v-if="USER_INFO.status === 'admin' && !docs.pre_order"
              >загрузить предварительный заказ</a
            >
            <span v-else>предварительный заказ</span>
          </th>
          <th>
            <a
              href="#"
              @click.prevent="upload('realization')"
              v-if="
                USER_INFO.status === 'admin' &&
                  order_info.order_confirm_user !== 0 &&
                  !docs.realization
              "
              >загрузить реализацию</a
            >
            <span v-else>реализация</span>
          </th>
          <th>
            <a
              href="#"
              @click.prevent="upload('invoice')"
              v-if="
                USER_INFO.status === 'admin' &&
                  order_info.order_confirm_user !== 0 &&
                  !docs.invoice
              "
              >загрузить счет</a
            >
            <span v-else>счет</span>
          </th>
        </tr>
        <tr>
          <td>
            <div v-if="docs.pre_order">
              <div v-for="(item, idx) in docs.pre_order" :key="idx">
                <a :href="url + item.path" target="_blank"
                  ><i class="bi bi-download"></i
                ></a>
                {{ item.name }}
                <a
                  href="#"
                  v-if="USER_INFO.status === 'admin'"
                  @click.prevent="del_doc('pre_order', item.name)"
                  ><i class="bi bi-trash"></i
                ></a>
                <span v-else></span>
              </div>
              <div v-if="!docs.order_confirm_user">
                <button
                  class="btn btn-sm btn-outline-success shadow-none confirm"
                  @click="orderConfirm"
                >
                  заказ подтверждаю
                </button>
              </div>
              <div v-else />
            </div>
            <div v-else>отсутствует</div>
          </td>
          <td>
            <div v-if="docs.realization">
              <div v-for="(item, idx) in docs.realization" :key="idx">
                <a :href="url + item.path" target="_blank"
                  ><i class="bi bi-download"></i
                ></a>
                {{ item.name }}
                <a
                  href="#"
                  v-if="USER_INFO.status === 'admin'"
                  @click.prevent="del_doc('realization', item.name)"
                  ><i class="bi bi-trash"></i
                ></a>
                <span v-else></span>
              </div>
            </div>
            <div v-else>отсутствует</div>
          </td>
          <td>
            <div v-if="docs.invoice">
              <div v-for="(item, idx) in docs.invoice" :key="idx">
                <a :href="url + item.path" target="_blank"
                  ><i class="bi bi-download"></i
                ></a>
                {{ item.name }}
                <a
                  href="#"
                  v-if="USER_INFO.status === 'admin'"
                  @click.prevent="del_doc('invoice', item.name)"
                  ><i class="bi bi-trash"></i
                ></a>
                <span v-else></span>
              </div>
            </div>
            <div v-else>отсутствует</div>
          </td>
        </tr>
      </table>
    </div>
    <input
      v-if="USER_INFO.status === 'admin'"
      type="file"
      id="file"
      ref="file"
      @change="getUpload"
      :key="inputKey"
      accept=" 
        application/vnd.ms-excel, 
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        "
    />
    <div v-else />
  </div>
</template>
<script>
import { HTTPPOSTFILE, HTTPGET } from "@/api";
import { mapGetters } from "vuex";
import u from "@/constants/";
export default {
  name: "OrderDocs",
  props: ["zap", "order_info"],
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    files: [],
    typeFile: null,
    inputKey: 0,
    docs: [],
    url: ""
  }),
  methods: {
    upload(type) {
      if (
        !this.order_info.order_finished ||
        (this.order_info.order_finished && type === "invoice")
      ) {
        this.typeFile = type;
        document.querySelector("#file").click();
      }
    },
    getUpload() {
      let formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      let data = {
        type: this.typeFile,
        id: this.$route.params.id
      };
      data = JSON.stringify(data);
      const zap = JSON.stringify(this.zap);
      formData.append("data", data);
      formData.append("zap", zap);
      HTTPPOSTFILE({
        url: "repairs/repairs_orders/add_file_for_order.php",
        data: formData
      })
        .then(response => {
          if (response.data.error) {
            alert(response.data.error);
          }
        })
        .then(() => this.get_docs())
        .then(() => this.$emit("upgradeHystoty"));

      this.typeFile = null;
      this.inputKey += 1;
      document.getElementById("file").value = "";
    },
    get_docs() {
      HTTPGET({
        url: "repairs/repairs_orders/get_order_docs.php",
        params: {
          id: this.$route.params.id
        }
      })
        .then(response => (this.docs = response.data))
        .then(() => this.$emit("info", this.docs));
    },
    del_doc(type, name) {
      if (
        !this.order_info.order_finished ||
        (this.order_info.order_finished && type === "invoice")
      ) {
        if (confirm(`вы уверены что файл  "${name}" нужно удалить?`)) {
          HTTPGET({
            url: "repairs/repairs_orders/del_order_docs.php",
            params: {
              type,
              name,
              id: this.$route.params.id
            }
          })
            .then(() => this.get_docs())
            .then(() => this.$emit("upgradeHystoty"));
        }
      }
    },
    orderConfirm() {
      if (confirm(`заказ подтверждаю`)) {
        HTTPGET({
          url: "repairs/repairs_orders/order_confirm.php",
          params: {
            id: this.$route.params.id
          }
        }).then(() => this.$emit("upgradeHystoty"));
      }
    },
    orderFinishedPercent() {
      if (confirm("завершить заявку")) {
        HTTPGET({
          url: "repairs/repairs_orders/order_confirm_percent.php",
          params: {
            id: this.$route.params.id
          }
        })
          .then(() => this.get_docs())
          .then(() => this.$emit("upgradeHystoty"));
      }
    }
  },
  mounted() {
    this.url = u.url;
    this.get_docs();
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin-top: 5px;
}
a {
  font-weight: bold;
}
td,
th {
  font-size: 0.8em;
}
th {
  vertical-align: middle;
}
#file {
  position: fixed;
  top: -10000px;
}
.select {
  display: flex;
  justify-content: space-evenly;
}
.confirm {
  font-size: 0.8em;
  margin-top: 5px;
}
</style>
