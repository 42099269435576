<template>
  <div v-if="order_info">
    <div class="path">
      <span v-for="(item, idx) in PATH" :key="idx">{{ item }}</span>
    </div>
    <div class="text-center header" v-if="order_info">
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none mb-1"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <button
        v-if="USER_INFO.status === 'admin' && !realization"
        class="back btn btn-sm btn-outline-success shadow-none mb-1"
        @click="getRepairsNotInOrder"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        добавить ремонт
      </button>

      <button
        v-if="
          USER_INFO.status === 'admin' &&
            !realization &&
            number_order_zap_not_for_repairs
        "
        class="back btn btn-sm btn-outline-warning shadow-none mb-1"
        @click="addZapFromOrderNotForRepairs(true)"
      >
        добавить запчасти из заказа №{{ number_order_zap_not_for_repairs }}
      </button>
      заявка №{{ order_info.id }} от
      {{ order_info.order_data }}
      {{ USER_INFO.status === "admin" ? order_info.user : "" }}
      <span
        class="bg-primary text-white p-1"
        v-if="USER_INFO.status === 'admin' && order_info.zap_free === '1'"
        >запчасти бесплатно</span
      >
      <span v-if="!repairs_list && !zapcount && USER_INFO.status === 'admin'"
        ><button
          class="btn btn-sm btn-outline-danger shadow-none"
          @click="deleteOrder"
        >
          удалить заявку
        </button>
      </span>
    </div>
    <div class="col-md-12 wrapper">
      <table class="table table-sm text-center" v-if="repairs_list">
        <thead>
          <tr>
            <th></th>
            <th v-if="USER_INFO.status === 'admin'"></th>
            <th>№</th>
            <th>№ СЦ</th>
            <th v-if="USER_INFO.status === 'admin'">СЦ</th>
            <th>создан</th>
            <th>инструмент</th>
            <th>группа</th>
            <th>S№</th>
            <th>дата продажи</th>
            <th>дата принятия в ремонт</th>
            <th v-if="USER_INFO.status === 'admin'"></th>
            <th>дефект</th>
            <th>продавец</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="tr"
            v-for="(item, idx) in repairs_list"
            :key="idx"
            :class="
              item.no_read_messages > 0 || item.zapCount === '0'
                ? 'message'
                : ''
            "
          >
            <td>
              {{ idx + 1 }}
            </td>
            <td v-if="USER_INFO.status === 'admin'">
              <div :id="item.remont_id">
                <i class="bi bi-list" @click="removeRepair(item)"></i>
              </div>
            </td>
            <td>
              <div
                class="nowrap"
                :class="
                  item.who_saw && USER_INFO.status === 'admin' ? 'who-saw' : ''
                "
              >
                {{ item.repair_number }}
              </div>
              <div v-if="item.who_saw_all && USER_INFO.status === 'admin'">
                <div
                  v-for="(item_who, idx) in item.who_saw_all"
                  :key="idx + 'who_saw'"
                >
                  {{ item_who.user }}
                </div>
              </div>
              <div :class="item.confirm_act_guid ? 'act' : ''">
                {{
                  item.confirm_act_guid ? `акт ${item.repair_confirm_data}` : ""
                }}
              </div>
              <div
                v-if="
                  item.duplicate_serial_number && USER_INFO.status === 'admin'
                "
              >
                <a
                  :href="
                    `https://sc-kalibr.ru/repairs/repair/${item.duplicate_serial_number}`
                  "
                  target="_blank"
                  @click.prevent="view_duplicate(item)"
                  >дубликат {{ item.duplicate_serial_number_count }}шт.</a
                >
              </div>
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.receiptNumber }}</td>
            <td
              @click="goRepair(item.remont_id)"
              v-if="USER_INFO.status === 'admin'"
            >
              {{ item.user }}
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.date_add }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.name_instr }}</td>
            <td @click="goRepair(item.remont_id)">
              {{ item.serialNumberGroup }}
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.serialNumber }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.dateSale }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.dateRepair }}</td>
            <td
              @click="goRepair(item.remont_id)"
              v-if="USER_INFO.status === 'admin'"
              :class="
                getDayFromDateRepair(item.dateRepairSql).panic &&
                  'text-danger font-weight-bold'
              "
            >
              {{ getDayFromDateRepair(item.dateRepairSql).countDay }}
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.defect }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.seller }}</td>
            <td @click="goRepair(item.remont_id)">
              <i class="bi bi-envelope-fill" v-if="item.no_read_messages > 0"
                ><a href="#" class="badge badge-danger"></a
              ></i>
              <i
                class="bi bi-pencil-square"
                v-if="item.allow_temp_edit === '0'"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 row">
      <div class="col-md-6 wrapper">
        <table class="table table-sm">
          <thead>
            <tr>
              <th colspan="8">
                запчасти по заявке
                <span>
                  <a
                    v-if="USER_INFO.status === 'admin'"
                    :href="
                      `${url}/api/repairs/repairs_orders/zap_in_excel.php?id=${order_id}&order=all`
                    "
                    target="_blank"
                    @click="goexcel"
                  >
                    импорт всех зч по заявке в excel
                  </a>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="new_order_zap">
              <th colspan="8">
                новый заказ
                <span>
                  <a
                    v-if="USER_INFO.status === 'admin'"
                    :href="
                      `${url}/api/repairs/repairs_orders/zap_in_excel.php?id=${order_id}&order=new_order_zap`
                    "
                    target="_blank"
                    @click="goexcel"
                  >
                    импорт в excel
                  </a>
                </span>
              </th>
            </tr>
            <tr
              v-for="(item, idx) in new_order_zap"
              :key="'a' + item.id"
              @click="show_zap_includes_in_repairs($event, item)"
            >
              <td class="nowrap">
                {{ idx + 1 }}
                <span
                  class="badge badge-success"
                  v-if="
                    item.zap_includes_in_repairs && USER_INFO.status === 'admin'
                  "
                  >{{ item.zap_includes_in_repairs.length }}</span
                >
              </td>
              <td class="ww" :title="item.name">
                {{
                  item.name.length > 55
                    ? `${item.name.substr(0, 55)}...`
                    : item.name
                }}
                <div class="number-nakl" v-if="item.nomer_nakl">
                  {{ item.nomer_nakl }} от {{ item.data_nakl }}
                </div>
              </td>
              <td>{{ item.cod }}</td>
              <td class="text-center">
                <b>{{ item.ostatok }}</b>
              </td>
              <td
                class="ww"
                :class="item.art.length < 11 && 'nowrap'"
                :title="item.art"
              >
                {{
                  item.art.length > 7 ? `${item.art.substr(0, 7)}...` : item.art
                }}
              </td>
              <td>{{ item.pcs }}шт.</td>
              <td class="nowrap">{{ item.repair_number }}</td>
              <td class="nowrap">{{ item.price }}</td>
            </tr>
            <tr v-if="user_old_zap">
              <th colspan="8">запчасти из ранее преобретенных</th>
            </tr>
            <tr v-for="(item, idx) in user_old_zap" :key="'b' + item.id">
              <td class="nowrap">
                {{ idx + 1 }}
                <span
                  class="badge badge-success"
                  v-if="
                    item.zap_includes_in_repairs && USER_INFO.status === 'admin'
                  "
                  >{{ item.zap_includes_in_repairs.length }}</span
                >
              </td>
              <td class="ww" :title="item.name">
                {{
                  item.name.length > 55
                    ? `${item.name.substr(0, 55)}...`
                    : item.name
                }}
                <div class="number-nakl">
                  {{ item.nomer_nakl }} от {{ item.data_nakl_format }}
                </div>
              </td>
              <td>{{ item.cod }}</td>
              <td></td>
              <td
                class="ww"
                :class="item.art.length < 11 && 'nowrap'"
                :title="item.art"
              >
                {{
                  item.art.length > 7 ? `${item.art.substr(0, 7)}...` : item.art
                }}
              </td>
              <td>{{ item.pcs }}шт.</td>
              <td class="nowrap">{{ item.repair_number }}</td>
              <td class="nowrap">{{ item.price }}</td>
            </tr>
            <tr v-if="zap_sc">
              <th colspan="8">собственные запчасти СЦ</th>
            </tr>
            <tr v-for="(item, idx) in zap_sc" :key="'c' + item.id">
              <td>{{ idx + 1 }}</td>
              <td class="ww">{{ item.name }}</td>
              <td></td>
              <td></td>
              <td>{{ item.pcs }}шт.</td>
              <td class="nowrap">{{ item.repair_number }}</td>
              <td class="nowrap">{{ item.cena }}</td>
            </tr>
            <tr v-if="zap_not_for_repairs">
              <th colspan="8">
                запчасти для не гарантийных ремонтов
                <span>
                  <a
                    v-if="USER_INFO.status === 'admin'"
                    :href="
                      `${url}/api/repairs/repairs_orders/zap_in_excel.php?id=${order_id}&order=zap_not_for_repairs`
                    "
                    target="_blank"
                    @click="goexcel"
                  >
                    импорт в excel
                  </a>
                </span>
              </th>
            </tr>
            <tr v-for="(item, idx) in zap_not_for_repairs" :key="'d' + item.id">
              <td>{{ idx + 1 }}</td>
              <td class="ww" :title="item.name">
                {{
                  item.name.length > 55
                    ? `${item.name.substr(0, 55)}...`
                    : item.name
                }}
              </td>
              <td>{{ item.cod }}</td>
              <td class="text-center">
                <b>{{ item.ostatok }}</b>
              </td>
              <td
                class="ww"
                :class="item.art.length < 11 && 'nowrap'"
                :title="item.art"
              >
                {{
                  item.art.length > 7 ? `${item.art.substr(0, 7)}...` : item.art
                }}
              </td>
              <td class="text-center">
                <div>{{ item.pcs }}шт.</div>
                <EditPcsZapNotForRepairs
                  :pcs="item.pcs"
                  :cod="item.cod"
                  v-if="USER_INFO.status === 'admin' && !realization"
                  @update="getZapOrder"
                  url="repairs/repairs_orders/edit_pcs_zap_not_for_repairs.php"
                />
              </td>
              <td></td>
              <td class="nowrap">
                {{ item.price ? `${item.price}р.` : "" }}
                <i
                  class="bi bi-trash"
                  @click="del_zap_not_for_repair(item.cod, item.name)"
                  v-if="
                    USER_INFO.status === 'admin' &&
                      !order_info.order_finished &&
                      !realization
                  "
                ></i>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <AddZapNotForRepairs
                  @update="update"
                  v-if="!order_info.order_finished && !realization"
                />
              </td>
            </tr>
            <tr v-if="order_info.comment">
              <td colspan="8">
                <div class="alert alert-primary bg-primary" role="alert">
                  <div class="text-white pb-1">комментарий</div>
                  <div class="bg-light p-2 text-dark">
                    {{ order_info.comment }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="chat col-md-6 wrapper">
        <Chat
          urlSendMessage="repairs/chat/chat_of_order.php"
          urlDeleteMessage="repairs/chat/del_message_order.php"
          chatId="chat_of_order"
          title="переписка по заявке"
        />
        <OrderHystory :key="orderHystoriKey" />
        <OrderDocs
          v-if="order_info"
          :zap="new_order_zap"
          @upgradeHystoty="upgradeHystoty"
          :key="orderDocsKey + 'p'"
          :order_info="order_info"
          @info="info"
        />
        <CdekInfo
          :order_info="order_info"
          @update="update"
          type="repairs_order"
        />
        <MessagesFofAllRepairsInOrder v-if="USER_INFO.status === 'admin'" />
      </div>
    </div>
    <RemoveRepair
      v-if="
        removeRepairData.visibleRemoveRepair && USER_INFO.status === 'admin'
      "
      :data="removeRepairData"
      :order_info="order_info"
      @closeRemoveRepair="closeRemoveRepair"
      :zap_not_for_repairs_length="
        zap_not_for_repairs ? zap_not_for_repairs.length : 0
      "
      @update="update"
      :key="removeRepairKey + 'r'"
    />
    <div v-else />
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-if="USER_INFO.status === 'admin'"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" v-if="add_repair_in_order">
            <h5 class="modal-title" id="exampleModalLabel">
              кликни нужный ремонт для добавления
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="add_repair_in_order">
            <ul class="list-group">
              <li
                class="list-group-item li-add"
                v-for="(item, idx) in add_repair_in_order"
                :key="idx"
                @click="addRepairInOrder(item.id, item.repair_number)"
              >
                {{ `${item.repair_number} ${item.name_instr}` }}
              </li>
            </ul>
          </div>
          <div class="modal-body text-center" v-else>
            ремонты отсутствуют
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal2"
      tabindex="-1"
      aria-labelledby="exampleModalLabel2"
      aria-hidden="true"
      v-if="USER_INFO.status === 'admin'"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="list-group" v-if="zap_includes_in_repairs_data">
            <a
              v-for="(item,
              idx) in zap_includes_in_repairs_data.zap_includes_in_repairs"
              :key="idx + 'zzz'"
              :href="`/repairs/repair/${item.remont_id}`"
              class="list-group-item list-group-item-action"
              target="_blank"
              >{{ `${idx + 1} ` }}{{ item.sn }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="USER_INFO.status === 'admin'"
      ref="mmm"
      data-toggle="modal"
      data-target="#exampleModal2"
    ></span>
    <DuplicateViews
      v-if="USER_INFO.status === 'admin'"
      :open="openDialog"
      :repairsId="duplicateId"
    />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapActions } from "vuex";
import OrderHystory from "./OrderHystory.vue";
import AddZapNotForRepairs from "./AddZapNotForRepairs.vue";
import OrderDocs from "./OrderDocs.vue";
import RemoveRepair from "./RemoveRepair.vue";
import Chat from "@/views/repairs/chat/Chat.vue";
import u from "@/constants/";
import EditPcsZapNotForRepairs from "./EditPcsZapNotForRepairs.vue";
import MessagesFofAllRepairsInOrder from "./MessagesFofAllRepairsInOrder.vue";
import CdekInfo from "./CdekInfo.vue";
import DuplicateViews from "@/components/DuplicateViews.vue";
export default {
  name: "OneOrder",
  components: {
    Chat,
    OrderHystory,
    OrderDocs,
    RemoveRepair,
    AddZapNotForRepairs,
    EditPcsZapNotForRepairs,
    MessagesFofAllRepairsInOrder,
    CdekInfo,
    DuplicateViews
  },
  computed: {
    ...mapGetters(["USER_INFO", "GET_PATH_FROM", "PATH"])
  },
  data: () => ({
    order_id: null,
    repairs_list: null,
    order_info: null,
    new_order_zap: [],
    zap_not_for_repairs: [],
    zapcount: true,
    user_old_zap: [],
    zap_sc: [],
    visibleZapNotForRepairs: false,
    url: "",
    orderHystoriKey: 0,
    orderDocsKey: 0,
    removeRepairKey: 0,
    removeRepairData: {
      repairData: null,
      visibleRemoveRepair: false
    },
    realization: null,
    add_repair_in_order: null,
    number_order_zap_not_for_repairs: null,
    visible_zap_includes_in_repairs: false,
    zap_includes_in_repairs_event: null,
    zap_includes_in_repairs_data: null,
    openDialog: false,
    duplicateId: null
  }),
  mounted() {
    this.order_id = this.$route.params.id;
    this.getOrder();
    this.getZapOrder();
    this.url = u.url;
    this.addZapFromOrderNotForRepairs(false);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    info(i) {
      this.realization = i.realization;
    },
    update() {
      this.getOrder();
      this.getZapOrder();
    },
    getOrder() {
      HTTPGET({
        url: "repairs/repairs_orders/get_one_order.php",
        params: { id: this.order_id }
      }).then(response => {
        this.repairs_list = response.data.repairs;
        this.order_info = response.data.order_info;
        this.ADDPATH(
          `${this.$route.query.path} / заявка №${this.order_info.id} ${this.order_info.user}`
        );
      });
    },
    getZapOrder() {
      HTTPGET({
        url: "repairs/repairs_orders/get_zap_order.php",
        params: { id: this.order_id }
      })
        .then(response => {
          this.new_order_zap = response.data.new_order_zap;
          this.zap_not_for_repairs = response.data.zap_not_for_repairs;
          this.user_old_zap = response.data.user_old_zap;
          this.zap_sc = response.data.zap_sc;
          this.zapcount = response.data.zapcount;
        })
        .then(() => {
          setTimeout(() => {
            window.scrollBy(0, window.localStorage.getItem("scrollMFRO"));
            window.localStorage.setItem("scrollMFRO", 0);
          }, 200);
        });
    },
    goRepair(id) {
      this.ADDPATH(
        `${this.$route.query.path} / заявка №${this.order_info.id} ${this.order_info.user}`
      ),
        this.$router.push(
          `/repairs/repair/${id}?from=repairs_order&order_id=${this.$route.params.id}`
        );
      window.localStorage.setItem("scrollMFRO", window.pageYOffset);
    },
    goexcel() {
      setTimeout(() => {
        this.upgradeHystoty();
      }, 1000);
    },
    upgradeHystoty() {
      this.orderHystoriKey += 1;
      this.orderDocsKey += 1;
      this.getOrder();
      this.getZapOrder();
    },
    removeRepair(remont) {
      const elem = document
        .getElementById(remont.remont_id)
        .getBoundingClientRect();
      this.removeRepairData.visibleRemoveRepair = true;
      this.removeRepairData.repairData = remont;
      this.removeRepairData.top = elem.top;
      this.removeRepairData.left = elem.left;
      this.removeRepairKey += 1;
    },
    closeRemoveRepair() {
      this.removeRepairData.visibleRemoveRepair = false;
    },
    del_zap_not_for_repair(cod, name) {
      if (confirm(`${name} - будет удален из заявки`)) {
        HTTPGET({
          url: "repairs/repairs_orders/del_zap_not_for_repair.php",
          params: {
            cod,
            id: this.$route.params.id
          }
        }).then(() => this.update());
      }
    },
    deleteOrder() {
      if (confirm("заявка и все документы по заявке будут удалены")) {
        HTTPGET({
          url: "repairs/repairs_orders/delete_order.php",
          params: {
            id: this.$route.params.id
          }
        })
          .then(() => this.update())
          .then(() => (this.orderDocsKey += 1))
          .then(() =>
            this.$router.push("/repairs/repairs-orders-list/not-finished")
          );
      }
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    getRepairsNotInOrder() {
      HTTPGET({
        url: "repairs/repairs_orders/add_repair_in_order.php",
        params: {
          get_repairs: this.order_info.id
        }
      }).then(response => (this.add_repair_in_order = response.data));
    },
    addRepairInOrder(id, number) {
      if (confirm(`добавить ${number} в завку`)) {
        HTTPGET({
          url: "repairs/repairs_orders/add_repair_in_order.php",
          params: {
            add_repair: id,
            order_id: this.$route.params.id
          }
        })
          .then(() => this.getRepairsNotInOrder())
          .then(() => this.getOrder())
          .then(() => this.getZapOrder());
      }
    },
    addZapFromOrderNotForRepairs(add) {
      if (add) {
        if (!confirm("добавить запчасчти в заявку")) {
          return;
        }
      }
      HTTPGET({
        url:
          "repairs/repairs_orders/add_in_order_zap_from_order_zap_not_for_repairs.php",
        params: {
          order_id: this.order_id,
          add: add
        }
      })
        .then(
          response =>
            (this.number_order_zap_not_for_repairs = response.data.order)
        )
        .then(() => {
          if (add) {
            this.getZapOrder();
            this.addZapFromOrderNotForRepairs(false);
          }
        });
    },
    show_zap_includes_in_repairs(event, item) {
      if (item.zap_includes_in_repairs) {
        this.zap_includes_in_repairs_event = event;
        this.zap_includes_in_repairs_data = item;
        this.$refs.mmm.click();
      } else {
        this.zap_includes_in_repairs_event = null;
        this.zap_includes_in_repairs_data = null;
      }
    },
    getDayFromDateRepair(dateRepairSql) {
      const start = new Date(dateRepairSql).getTime();
      //const end = new Date().getTime();
      const end = new Date(this.order_info.order_data_sql).getTime();
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = end - start;
      const diffInDays = Math.round(diffInTime / oneDay);
      let panic = "";
      if (diffInDays > 10) {
        panic = "red";
      }
      return { countDay: diffInDays, panic };
    },
    view_duplicate(id) {
      this.duplicateId = id;
      this.openDialog = !this.openDialog;
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.8em;
}
.tr:hover {
  cursor: pointer;
  background: rgb(247, 247, 247);
}
.message {
  color: red;
}
.nowrap {
  white-space: nowrap;
}
th {
  text-align: center;
}
td {
  vertical-align: middle;
}
.wrapper {
  overflow: auto;
}
.ww {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}
.fr {
  float: right;
}
.number-nakl {
  color: #007bff;
}
.act {
  color: #ff0000;
  font-weight: bold;
  font-size: 0.8em;
}
i {
  cursor: pointer;
}
.bi-trash,
.bi-pencil-square {
  color: #ff0000;
}
.back {
  float: left;
}
.btn {
  margin-right: 10px;
}
.header {
  margin: 5px;
  margin-bottom: 15px;
}
.path {
  margin-left: 10px;
  font-size: 0.7em;
  color: rgb(168, 180, 180);
}
.li-add {
  cursor: pointer;
}
.li-add:hover {
  background: rgb(221, 221, 221);
}
.who-saw {
  background: green;
  color: white;
}
</style>
